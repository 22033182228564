import React from 'react';
//import { graphql, useStaticQuery } from 'gatsby';
//import Markdown from 'react-markdown';

import Layout from '../components/layout';
import SEO from '../components/seo';
// import dogIllustration from '../images/dog-illustration.svg';

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `1С`,
          `Программы 1С`,
          `О компании`,
          `софтмакс`,
          `купить 1с Пыть-Ях`
        ]}
        title='О компании'
      />

      <section className='flex flex-col items-center md:flex-row px-6'>
        <div className='md:w-2/3 md:mr-8'>
          <blockquote className='pl-4 font-serif leading-loose text-justify border-l-4 border-gray-900'>
            Компания &quot; Софтмакс&quot; родилась в 2003 году и сразу начала
            свою деятельность как партнер-франчайзи Фирмы &quot;1С&quot;.
            Коллектив в текущем виде сформировался уже более 10 лет назад и
            имеет богатый опыт в автоматизации учета в самых разных компаниях:
            от маленького ИП и до фирм с обособленными подразделениями в разных
            регионах России. Мы дорожим крепкими деловыми отношениями с нашими
            клиентами и всегда готовы прийти на помощь, когда они нуждаются в
            ней.
          </blockquote>

          <cite className='block mt-4 text-xs font-bold text-right uppercase'>
            – Adel Rezvanov
          </cite>
        </div>

        {/* <figure className='w-2/3 md:w-1/3'>
          <img alt='A dog relaxing' src={dogIllustration} />
        </figure> */}
      </section>
    </Layout>
  );
}

export default AboutPage;
